(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('currencyInput', hippoCurrencyInputDirective);
    hippoCurrencyInputDirective.$inject = ['$timeout', '$filter'];
    function hippoCurrencyInputDirective ($timeout, $filter) {
        var formatter = function (num) {
            // console.log('formatter', num);
            if (num === '' || isNaN(num)) return undefined;
            var decimals = 0;
            if (num * 100 % 100) decimals = 2;

            var formatted = $filter('currency')(num, '$', decimals);
            if (num < 0) formatted = '(' + formatted + ')';
            return formatted;
        };

        var parser = function (str) {
            // console.log('parser', str);
            if (str === '') return str;

            return parseFloat((str + '').replace(/[^0-9\-.]/g, ''));
        };

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                ngModelCtrl.$parsers.push(parser);
                ngModelCtrl.$formatters.push(formatter);

                $element.bind('blur', function () {
                    $element.val(formatter(ngModelCtrl.$modelValue));
                });
                $element.bind('focus', function () {
                    $element.val(ngModelCtrl.$modelValue);
                });
            }
        };
    }
})(window.angular, window._);
